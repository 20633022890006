.chat-button {
  background-color: #00a1fc;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 1rem 2rem;
  font-size: 16px;
  cursor: pointer;
  bottom: 18px;
  right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  z-index: 99999999999;
  position: absolute;

  @media screen and (max-width: 768px) {
    padding: 0.5rem 1.5rem;
  }
}

.chat-icon-svg {
  margin-top: 4px;

  @media screen and (max-width: 768px) {
    width: 28px;
    height: 28px;
  }
}

.chat-button:hover {
  background-color: #0056b3;
}
